import slugify from 'slugify';
import tippy from 'tippy.js';
import ClipboardJS from 'clipboard';

const SLUG_PREFIX = 'anchor';

function createAnchor(slug) {
    const anchor = document.createElement('span');

    anchor.className = 'anchor';
    anchor.href = `${window.location.origin}${window.location.pathname}#${encodeURIComponent(slug)}`;
    anchor.dataset.anchor = slug;
    anchor.addEventListener('click', e => e.stopPropagation());

    // Show a tooltip on anchor hover
    // noinspection JSDeprecatedSymbols
    tippy(anchor, { content: window.AppLabels.anchors['copyTitle'] });

    // Copy the URL to clipboard on clicking the anchor
    const clipboard = new ClipboardJS(anchor, { text: () => anchor.href });
    clipboard.on('success', () => {
        // noinspection JSDeprecatedSymbols
        tippy(anchor, { content: window.AppLabels.anchors['copied'], trigger: '' }).show();
    });

    return anchor;
}

function scrollToCurrentAnchor(initial = false) {
    if (!window.location.hash || window.location.hash.indexOf(`#${SLUG_PREFIX}-`) !== 0) {
        return
    }

    const hash = decodeURIComponent(window.location.hash.substring(1));
    const el = document.querySelector(`[data-anchor="${hash}"]`);

    if (!el) {
        return;
    }

    let parent = el.parentNode;

    // Check if the element is within an accordion
    do {
        // Open all accordions up in the tree
        if (parent.classList.contains('handorgel__header')) {
            initial ? parent.nextElementSibling.dataset.open = '1' : parent.handorgelFold.open(false);
        }

        if (parent.classList.contains('handorgel__content')) {
            initial ? parent.dataset.open = '1' : parent.handorgelFold.open(false);
        }

        parent = parent.parentNode;
    } while (parent !== document.body && parent !== null);

    // Scroll element into view
    setTimeout(() => {
        // Use scrollTo for IE11
        if (!!window.MSInputMethodContext && !!document.documentMode) {
            window.scrollTo(0, el.parentNode.getBoundingClientRect().top);
        } else {
            window.scroll({ top: el.parentNode.getBoundingClientRect().top + window.scrollY, behavior: 'smooth' });
        }
    }, 500);
}

export default () => {
    const selectors = [
        // Accordion triggers
        '.handorgel__header__button',

        // Headlines
        'h2.ce_headline',
        'h3.ce_headline',
        'h4.ce_headline',
        'h5.ce_headline',
        'h6.ce_headline',

        // Headlines inside text elements
        '.ce_text h2',
        '.ce_text h3',
        '.ce_text h4',
        '.ce_text h5',
        '.ce_text h6',

        // Headlines inside gallery elements
        '.ce_gallery h2',
        '.ce_gallery h3',
        '.ce_gallery h4',
        '.ce_gallery h5',
        '.ce_gallery h6',

        // Headlines inside image elements
        '.ce_image h2',
        '.ce_image h3',
        '.ce_image h4',
        '.ce_image h5',
        '.ce_image h6',

        // Headlines inside animation elements
        '.ce_app_animation h2',
        '.ce_app_animation h3',
        '.ce_app_animation h4',
        '.ce_app_animation h5',
        '.ce_app_animation h6',

        // Video list module
        '.mod_app_videos h2',
        '.mod_app_videos h3',
        '.mod_app_videos h4',
        '.mod_app_videos h5',
        '.mod_app_videos h6',
    ];

    selectors.forEach(selector => {
        const els = [...document.querySelectorAll(selector)];
        const slugs = {};

        // Create anchors for elements
        els.forEach(el => {
            let slug = `${SLUG_PREFIX}-${slugify(el.innerText, { lower: true, locale: document.documentElement.lang })}`;

            // Handle duplicate slugs
            if (slugs.hasOwnProperty(slug)) {
                slug = `${slug}-${slugs[slug]++}`;
            } else {
                slugs[slug] = 1;
            }

            const anchor = createAnchor(slug);

            el.classList.add('anchor-wrapper');
            el.appendChild(anchor);
        });
    });

    // Get all anchor links pointing to the current page and make sure clicking them opens accordions even if
    // the hash in the URL does not change
    [...document.querySelectorAll(`a[href*="#${SLUG_PREFIX}-"]`)].forEach(link => {
        if (link.pathname === window.location.pathname && link.protocol === window.location.protocol && link.host === window.location.host) {
            link.addEventListener('click', () => scrollToCurrentAnchor());
        }
    });

    window.addEventListener('hashchange', () => scrollToCurrentAnchor());
    scrollToCurrentAnchor(true);
}
